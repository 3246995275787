import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {options} from "../styles/options";

const Event = ({event, locale}) => {
    return (
        <Card sx={{display: 'flex', flexDirection: 'column', marginBlockEnd: '0.5rem'}}>
            <CardContent>{documentToReactComponents(event.fields[locale], options)}</CardContent>
            <CardMedia
                component={'img'}
                image={event.fields['imageUrl']}
                sx={{maxWidth: `90%`, alignSelf: `center`}}
            />
        </Card>
    )
}

export default Event
