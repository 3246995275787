import {GatsbyImage} from "gatsby-plugin-image";
import {FormattedMessage, useIntl, Link} from 'gatsby-plugin-intl'
import flowRight from 'lodash.flowright'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import {autoPlay, bindKeyboard} from "react-swipeable-views-utils"
import styled from 'styled-components'
import {StyledLink} from '../styles/common'
import {theme} from '../styles/theme'
import {GTMobile} from '../utils/responsive'
import {ChevronLeft, ChevronRight} from './icons';

const AutoPlaySwipeView = flowRight(
    bindKeyboard,
    autoPlay,
)(SwipeableViews)

const Slide = styled.div`
  padding: 15px 0;
`

const Image = styled(GatsbyImage)`
  margin: 0 auto;
  height: 450px;
  border: 1px solid ${props => props.theme.colors.caramel};
  @media all and (max-width: 320px) {
    height: 300px;
  }
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0 0.2rem;
`

const Dots = styled.div`
  display: flex;
  padding: 0.25rem;
`

const Stepper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.caramelCloud};
  padding: 0 1rem;
  //width: calc(100% - 30px);
  margin: 0 auto 1rem;
`

const Button = styled.button`
  padding: 0.5rem;
  background: transparent;
  color: ${props => props.theme.colors.caramel};
  border-radius: 4px;
  border: 0;
  min-width: 100px;
  cursor: pointer;
`

const Title = styled.div`
  max-width: 100%;
  height: fit-content;
  color: ${props => props.theme.colors.caramel};

  h3 {
    margin: 0;
    color: ${props => props.theme.colors.caramel};
    @media all and (max-width: 767px) {
      margin-bottom: 1.45rem;
    }
  }

  p {
    margin: 0;
    padding: 0.25rem 0.5rem;
    border: 1px solid ${props => props.theme.colors.caramel};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 767px) {
      padding: 0.25rem 0.75rem;
    }
  }

  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 767px) {
    flex-direction: column;
  }
`

const Carousel = ({media}) => {
    const [activeIndex, setActiveIndex] = React.useState(0)
    const intl = useIntl()
    const code = intl.locale.charAt(0).toUpperCase() + intl.locale.charAt(1)
    const locale = `title${code}`

    const maxIndex = media.length - 1

    const handleSlideChange = curr => setActiveIndex(curr)

    const handleBack = () => setActiveIndex(activeIndex - 1)

    const handleNext = () => setActiveIndex(activeIndex + 1)

    return (
        <div>
            <AutoPlaySwipeView
                interval={5000}
                onChangeIndex={handleSlideChange}
                index={activeIndex}
                ignoreNativeScroll={true}>
                {media.map((item, index) => {
                    const album = `/${item.node.name}/`
                    const title = item.node[locale]

                    return (
                        <Slide key={index}>
                            <Image image={item.node['frontpage'].fluid} alt={'Slide image'}/>
                            <Link to={album}>
                                <Title>
                                    <h3>{title}</h3>
                                    <p>
                                        <FormattedMessage id={`see_album`}/>
                                    </p>
                                </Title>
                            </Link>
                        </Slide>
                    )
                })}
            </AutoPlaySwipeView>
            <GTMobile>
                <Stepper>
                    <Button onClick={handleBack} disabled={activeIndex === 0}>
                        <ChevronLeft size={32} color={theme.colors.caramel}/>
                    </Button>
                    <Dots>
                        {media.map((_, i) => (
                            <Dot
                                key={i}
                                style={{
                                    background:
                                        i === activeIndex
                                            ? theme.colors.caramel
                                            : theme.colors.cream,
                                }}
                            />
                        ))}
                    </Dots>
                    <Button onClick={handleNext} disabled={activeIndex === maxIndex}>
                        <ChevronRight size={32} color={theme.colors.caramel}/>
                    </Button>
                </Stepper>
            </GTMobile>
            <StyledLink to={'/albums/'} style={{marginLeft: `16px`}}>
                <FormattedMessage id={`see_all_albums`} />
            </StyledLink>
        </div>
    )
}

export default Carousel
