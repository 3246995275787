import {injectIntl} from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import {StyledLink} from '../styles/common'
import {theme} from "../styles/theme";

const Level = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${theme.colors.cream};
  min-height: 5.5rem;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`

const Menu = ({intl, pages}) => (
    <Level>
        {pages.map((page, index) => {
            const url = `${page.url}/`

            return (
                <StyledLink to={url} key={index}>
                    {intl.formatMessage({id: page.code})}
                </StyledLink>
            )
        })}
    </Level>
)

export default injectIntl(Menu)
