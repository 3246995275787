import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import {graphql} from 'gatsby'
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Event from '../components/event'
import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Service from '../components/service'
import Carousel from '../components/swipe'
import {GlobalDispatchContext, GlobalStateContext,} from '../context/GlobalContextProvider'
import {fetchEvents, fetchInfo, fetchServices} from '../data/contentful'
import {StyledLink} from '../styles/common'
import {Hero, HeroContainer, StyledParagraph, Subtitle} from '../styles/index'
import {options} from '../styles/options'
import {GTTablet} from '../utils/responsive'
import {orderByDateAsc} from "../utils/helpers";

const Pages = [
    {code: 'about', url: '/about', key: 3},
    {code: 'activities', url: '/activities', key: 4},
    {code: 'organisation', url: '/organisation', key: 5},
    {code: 'faq', url: '/faq', key: 6},
]

const IndexPage = ({data: {allContentfulAlbum, contentfulAlbum}}) => {
    const intl = useIntl()
    const globalState = useRef(useContext(GlobalStateContext))
    const dispatch = useContext(GlobalDispatchContext)
    const heroImage = contentfulAlbum['frontpage'].fluid
    const [nextServices, setNextServices] = useState(globalState.current.services)
    const [nextEvents, setNextEvents] = useState(globalState.current.events)
    const [info, setInfo] = useState(globalState.current.info)

    const carouselImages = allContentfulAlbum.edges.filter(
        (edge) => edge.node['frontpage'] !== null,
    )

    useEffect(() => {
        if (!globalState.current.services) {
            async function getServices() {
                const services = await fetchServices()
                setNextServices(services)
                dispatch({type: 'SET_SERVICES', value: services})
            }

            getServices().then((r) => r)
        }
    }, [dispatch])

    useEffect(() => {
        if (!globalState.current.events) {
            async function getEvents() {
                const data = await fetchEvents()
                const events = data.sort(orderByDateAsc).slice(0, 2)
                setNextEvents(events)
                dispatch({type: 'SET_EVENTS', value: events})
            }

            getEvents().then((r) => r)
        }
    }, [dispatch])

    useEffect(() => {
        if (!globalState.current.info) {
            async function getInfo() {
                const infoItem = await fetchInfo()
                setInfo(infoItem)
                dispatch({type: 'SET_INFO', value: infoItem})
            }

            getInfo().then((r) => r)
        }
    }, [dispatch])

    return (
        <Layout>
            <Seo title={intl.formatMessage({id: 'start_page'})}/>
            <HeroContainer>
                <Hero image={heroImage} alt={'Index page image'}/>
                <GTTablet>
                    <Menu pages={Pages}/>
                </GTTablet>
            </HeroContainer>
            <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12} lg={6}>
                    <Grid item xs={12}>
                        <Subtitle>
                            <FormattedMessage id={'subtitle_info'}/>
                        </Subtitle>
                    </Grid>
                    {info && info.length ? (
                        <Grid item xs={12}>
                            <Card sx={{display: 'flex', flexDirection: 'column'}}>
                                <CardContent>
                                    {documentToReactComponents(
                                        info[0]['fields'][intl.locale],
                                        options,
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container spacing={2} item xs={12} lg={6} sx={{marginBlockEnd: `1rem`}}>
                    <Grid item xs={12}>
                        <Subtitle>
                            <FormattedMessage id={'subtitle_services'}/>
                        </Subtitle>
                    </Grid>
                    {nextServices ? (
                        <Grid container spacing={2} item xs={12}>
                            {nextServices.map((service, i) => (
                                <Grid item xs={12} key={i} sx={{marginInline: `16px`}}>
                                    <Service text={service.fields[intl.locale]}/>
                                </Grid>
                            ))}
                            <Grid item xs={12} sx={{marginInline: `16px`}}>
                                <StyledLink to={'/schedule/'}>
                                    <FormattedMessage id={'all_services'}/>
                                </StyledLink>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <StyledParagraph>
                                <FormattedMessage id={'no_services'}/>
                            </StyledParagraph>
                        </Grid>
                    )}
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    sx={{justifyContent: `center`}}>
                    {nextEvents ? (
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Subtitle>
                                    <FormattedMessage id={'subtitle_events'}/>
                                </Subtitle>
                            </Grid>
                            {nextEvents.length ? (
                                nextEvents.map((event, i) => (
                                    <Grid item xs={12} md={6}>
                                        <Event key={i} event={event} locale={intl.locale}/>
                                    </Grid>
                                ))
                            ) : (
                                <StyledParagraph>
                                    <FormattedMessage id={'no_events'}/>
                                </StyledParagraph>
                            )}
                        </Grid>
                    ) : null}

                    <Grid item xs={12} sm={10} md={6} lg={4}>
                        <Subtitle>
                            <FormattedMessage id={'subtitle_albums'}/>
                        </Subtitle>
                        <Carousel media={carouselImages}/>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}

export const indexPageQuery = graphql`
  query {
    contentfulAlbum(name: { eq: "stock" }) {
      frontpage {
        fluid: gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allContentfulAlbum(
      sort: { fields: [date], order: DESC }
      filter: { name: { ne: "stock" } }
    ) {
      edges {
        node {
          name
          titleEn
          titleRu
          titleNl
          frontpage {
            fluid: gatsbyImageData(layout: FULL_WIDTH)
          }
          date
        }
      }
    }
  }
`

export default IndexPage
